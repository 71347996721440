import {roleType} from 'constants/constants';
import AssessmentStatsView from './AssessmentStats.view';
import {useGetAssessmentStats} from 'api/CoachAndDirector/useGetAssessmentStats';

interface AssessmentStatsProps {
  assessmentId: string;
}

const AssessmentStats = ({assessmentId}: AssessmentStatsProps) => {
  const {data: assessmentStats} = useGetAssessmentStats(assessmentId, roleType.teamCoach);

  const totalPlayers = assessmentStats?.totalPlayers ?? 0;
  const assignedSessions = assessmentStats?.assignedSessions ?? 0;
  const completedSessions = assessmentStats?.completedSessions ?? 0;

  const yetToComplete = totalPlayers - completedSessions;

  return (
    <AssessmentStatsView
      totalPlayers={totalPlayers}
      assignedSessions={assignedSessions}
      completedSessions={completedSessions}
      yetToComplete={yetToComplete}
    />
  );
};

export default AssessmentStats;
