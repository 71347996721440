import QRCode from 'qrcode.react';
import Spinner from 'components/Loader/Spinner';
import {Button} from 'components/Connects/Button';
import {ReactComponent as ShareIcon} from '../../../assets/img/icons/share-icon.svg';

interface InviteViewProps {
  isLoading: boolean;
  inviteLink: string;
  handleInviteLinkShare: () => void;
}

const InviteView = ({isLoading, inviteLink, handleInviteLinkShare}: InviteViewProps) => {
  return (
    <div className="flex-1 flex flex-col w-full justify-between">
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col">
            <div className="flex flex-col items-center">
              <div className="text-5xl text-primary leading-[60px] font-semibold mt-10">INVITE TO JOIN</div>
              <QRCode className="mt-10" value={inviteLink} size={196} />
              <div className="mt-11 text-sm">Please scan the bar code to access the team invitation to join!!</div>
            </div>
          </div>
          <div className="exceed-global-padding p-6 border-2 border-grey">
            <Button type="submit" color="secondary" variant="cta" onClick={handleInviteLinkShare}>
              <div className="flex items-start">
                <span>Share</span>
                <ShareIcon className="text-white fill-white h-6 w-6 cursor-pointer ml-2" />
              </div>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default InviteView;
