import {useQuery} from '@tanstack/react-query';
import {getAssessmentPlayers} from 'api/api';
import {Profile} from 'user/player-info.interface';

export const useGetAssessmentPlayers = (assessmentId: string, yearOfBirth: string, sortBy: string, role: string) => {
  return useQuery<Profile[]>({
    queryKey: ['assessment-players', assessmentId, yearOfBirth, sortBy, role],
    queryFn: () => getAssessmentPlayers(assessmentId, yearOfBirth, sortBy, role),
    enabled: !!assessmentId,
  });
};
