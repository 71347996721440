import {useNavigate} from 'react-router-dom';
import {useProfiles} from 'api/User/useProfiles';
import {useLogout} from 'hooks/useLogout';
import TeamsView from 'pages/Coach/Teams/Teams.view';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {roleType} from 'constants/constants';

const Teams = () => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isLoading} = useGetDirectorInfo(currentProfile?._id);
  const {logout} = useLogout();

  const teams = directorInfo?.teams;

  const onTeamSelect = (teamId: string) => {
    navigate(`teams/${teamId}`);
  };

  return (
    <TeamsView onTeamSelect={onTeamSelect} teams={teams} logout={logout} loading={isLoading} role={roleType.director} />
  );
};

export default Teams;
