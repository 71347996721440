import {useState, ChangeEvent, useRef} from 'react';
import uploadLogo from 'assets/img/image-upload.svg';

interface ImageUploadProps {
  defaultImageUrl: string;
  onImageSelect: (file: File | string) => void;
}

/**
 * A component that allows users to upload and preview images.
 */
const ImageUpload = ({defaultImageUrl, onImageSelect}: ImageUploadProps) => {
  const [, setSelectedFile] = useState<string | File>('');
  const [previewImage, setPreviewImage] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
      onImageSelect(file);
    }
  };

  /**
   * Handles the click event on the logo.
   */
  const handleClickOnLogo = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger the file input click
    }
  };

  return (
    <div className="relative cursor-pointer w-32 h-32" onClick={handleClickOnLogo}>
      <img src={previewImage || defaultImageUrl} alt="Team logo" className="rounded-full w-32 h-32" />
      <input
        ref={fileInputRef}
        className="hidden"
        type="file"
        name="file"
        accept="image/*"
        onChange={handleFileInputChange}
      />
      <img src={uploadLogo} className="absolute right-0 bottom-0" />
    </div>
  );
};

export default ImageUpload;
