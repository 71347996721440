import {useMemo} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import {
  AssessmentPlayerPerformanceData,
  useGetSelectedPlayersAssessmentData,
} from 'api/CoachAndDirector/useGetSelectedPlayersAssessmentData';
import {Assessment} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {Team} from 'user/user.interface';
import {roleType} from 'constants/constants';
import PlayerAssessmentDataView from 'pages/Coach/PlayerAssessmentData/PlayerAssessmentData.view';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {convertDateRange, downloadPerformanceDataAsExcel} from 'pages/Coach/utils';
import {useGetAssessment} from 'api/CoachAndDirector/useGetAssessment';

const PlayerAssessmentData = () => {
  const {teamId} = useParams();
  const [searchParams] = useSearchParams();
  const {currentProfile} = useProfiles();
  const {data: directorInfo} = useGetDirectorInfo(currentProfile?._id);
  const [playerId, assessmentId] = [searchParams.get('playerId') ?? '', searchParams.get('assessmentId') ?? ''];
  const {data: assessmentInfo} = useGetAssessment(assessmentId, roleType.director);
  const {data: playersAssessmentData, isLoading} = useGetSelectedPlayersAssessmentData({
    playerIds: playerId,
    assessmentId,
    role: roleType.director,
    sortBy: '',
  });
  const teamInfo = useMemo(
    () => directorInfo?.teams.find((team) => team._id === teamId),
    [teamId, directorInfo?.teams]
  );

  const date = convertDateRange(assessmentInfo?.startDate ?? '', assessmentInfo?.endDate ?? '');
  const playerAssessmentData = playersAssessmentData?.[0]?.performanceDetails;

  const isSessionSelectionCompleted = useMemo(
    () =>
      playerAssessmentData?.exerciseSummary?.find((exercise) => {
        return 'confirmedBy' in exercise;
      }),
    [playerAssessmentData]
  );

  const onClickDownloadHandler = async () => {
    downloadPerformanceDataAsExcel(
      playersAssessmentData as AssessmentPlayerPerformanceData[],
      assessmentInfo as Assessment,
      teamInfo as Team
    );
  };

  return (
    <PlayerAssessmentDataView
      isLoading={isLoading}
      playerAssessmentData={playerAssessmentData as PlayerSessionSummary}
      isSessionSelectionCompleted={!!isSessionSelectionCompleted}
      date={date}
      onClickDownloadHandler={onClickDownloadHandler}
    />
  );
};

export default PlayerAssessmentData;
