import {memo, useCallback, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import store from 'redux/store';
import {useAppDispatch} from 'redux/reduxUtils';
import {useQueryClient} from '@tanstack/react-query';
import {Helmet} from 'react-helmet';
import {usePrefetchPrograms} from 'api/Classes/useGetPrograms';
import {MenuBar} from 'components/MenuBar/MenuBar';
import {FkoRegistrationLandingForm, RegistrationFormTypes} from 'pages/RegistrationLanding/RegistrationLandingForm';
import {verifyRecaptcha} from 'api/api';
import {parseFkoParamsFromSearchParams, recaptchaSiteKey, sendFkoHubspotData} from 'common/fko-utils';
import {
  createUserInBackground,
  setFkoInitialParams,
  setFkoPlayersArray,
  setFkoUserData,
} from 'redux/slices/fkoFormData';
import {useGetCachedUserExists} from 'api/FKO/useGetCachedUserExists';
import {useLoadScript} from 'common/use-load-script.hook';
import {logAnalyticsEvent} from 'common/analytics-events';
import {getFkoInitialParamsLocalstorage, setFkoInitialParamsLocalstorage} from 'common/fko-localstorage';
import {useGetSport} from 'common/useGetSport';
import {IS_TEST_ENVIRONMENT} from 'common/isTestEnvironment';
import {executeRecaptchaV3} from 'utils/utils';
import {PageHeading} from 'components/Connects/PageHeading';
import {setCurrentAccountHolderProfileId, setCurrentAccountId} from 'user/user-utils';
import Spinner from 'components/Loader/Spinner';
import {isLocationPermitted} from 'feature-flags/fko-locations';
import {getLocationBySiteId} from 'constants/locations';
import {Button} from 'components/Connects/Button';

export const ProgramsPromotionalLanding = memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {sessionId, siteId} = useParams();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const {getCachedUserExists} = useGetCachedUserExists();
  const {basePathWithoutSessionId, sport, fkoMethod, fkoAnalyticsPrefix, isClasses, isFreeTrialClasses} = useGetSport();

  useLoadScript(`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`);
  usePrefetchPrograms({siteId, select: (programs) => programs.filter((p) => p.classSchedules?.length)});

  useEffect(() => {
    logAnalyticsEvent(`classes_form_start`);
    logAnalyticsEvent(`${fkoAnalyticsPrefix}_landing_view`); // TODO remove, probably
    const urlParams = parseFkoParamsFromSearchParams(new URL(document.location.href).searchParams);
    // if we have a valid sessionId, we reload the correct initial params (this is probably a page refresh)
    if (sessionId) {
      const _classesParams = getFkoInitialParamsLocalstorage({paramsId: sessionId, sport}) ?? {...urlParams, sport};
      dispatch(setFkoInitialParams(_classesParams));
    } else {
      const classesParams = {...urlParams, sport};
      const {paramsId} = setFkoInitialParamsLocalstorage(classesParams);
      dispatch(setFkoInitialParams(classesParams));
      navigate(`/${paramsId}${basePathWithoutSessionId}`, {replace: true});
    }
    // ---don't include `sessionId` in dependencies---
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePathWithoutSessionId, dispatch, fkoAnalyticsPrefix, navigate, sport]);

  // note: This is being used only to check if a user exists, not for confirming eligibility
  // as is the case during FKO. The classes eligibility check is at the ProgramsCheckout level.
  const checkUserExists = useCallback(
    async ({email, formData}: {email: string; formData?: RegistrationFormTypes}) => {
      getCachedUserExists({email})
        .then((res) => {
          const {userId, profiles} = res.data;

          if (userId) {
            const accountHolder = profiles?.find((profile) => profile.accountHolder) ?? profiles?.[0];
            if (!profiles?.length || !accountHolder) {
              console.error('user exists but no profiles found'); // TODO CLASSES
              return;
            }
            setCurrentAccountId(userId);
            setCurrentAccountHolderProfileId(accountHolder._id);
            dispatch(
              // this might be used later to prefill a contact form
              setFkoUserData({
                accountId: userId,
                dob: accountHolder.dob,
                email,
                firstName: accountHolder.firstName,
                lastName: accountHolder.lastName,
                location: accountHolder.mboDetails?.siteId ?? siteId ?? '',
                phoneNumber: formData?.phoneNumber,
              })
            );
            dispatch(setFkoPlayersArray(profiles));
          } else if (formData && queryClient) {
            dispatch(setFkoUserData(formData));

            dispatch(createUserInBackground({email, formData, queryClient, isClasses: true}));
          } else {
            throw new Error('599 Error: no userId or formData');
          }
        })
        .catch((err) => {
          setErrorMessage(`${err.includes('599') ? '599' : '588'} Error checking user email`);
        });
    },
    [dispatch, getCachedUserExists, navigate, queryClient]
  );

  const onSubmitHandler = useCallback(async (data: RegistrationFormTypes) => {
    setIsLoading(true);
    if (!IS_TEST_ENVIRONMENT) {
      try {
        const recaptchaToken = await executeRecaptchaV3(recaptchaSiteKey);
        const recaptchaResult = await verifyRecaptcha(recaptchaToken);
        if (!recaptchaResult.success || recaptchaResult.score < 0.3) {
          setErrorMessage('reCAPTCHA challenge failed');
          return;
        }
      } catch (err) {
        setErrorMessage('reCAPTCHA challenge failed');
        return;
      }
    }
    const initialParams = store.getState().fkoFormDataReducer.initialParams;
    // TODO CLASSES specific requirements for hubspot here?
    sendFkoHubspotData({
      alternateProfileData: data,
      fkoMethod,
      initialParams,
      locationId: data.location,
    });

    checkUserExists({email: data.email, formData: data}).finally(() => {
      setIsLoading(false);
      navigate(`${data.location}/${initialParams.trainingProgram ?? ''}`);
    });
  }, []);

  let locationIsBlocked = false;
  const {locationId} = store.getState().fkoFormDataReducer.initialParams;
  if (locationId) {
    const location = getLocationBySiteId(locationId);
    if (!location) {
      locationIsBlocked = true;
    } else {
      locationIsBlocked = !isLocationPermitted({locationId, isClasses, isFreeTrialClasses});
    }
  }

  return (
    <>
      <Helmet>
        <title>TOCA Soccer | Classes</title>
      </Helmet>

      <div className="grow flex flex-col gap-3 sm:gap-6">
        <MenuBar showHamburger={false} />

        <div className="flex flex-col items-center my-4">
          <PageHeading text="TOCA Soccer Classes" />
        </div>

        {locationIsBlocked ? (
          <div className="grid place-content-center w-full py-8">
            <div className="max-w-[360px] flex flex-col gap-3 items-center px-8 py-6 bg-white shadow-flat-lg shadow-primary/20 rounded text-center">
              <p className="text-lg text-primary text-center">This location does not yet offer online class booking</p>
              <p className="text-base text-black font-light">Please check back soon!</p>
              <Link to={`/ftc`} className="mt-4">
                <Button text="see other class options" />
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-8">
            <p className="max-w-[400px] text-base text-primary font-medium text-center">
              Schedule a FREE, no-commitment class for your child.
            </p>

            <div className="relative grow flex flex-col max-w-[600px] py-4 px-8 bg-white border border-primary/20 rounded shadow-flat-lg mb-12">
              {isLoading ? (
                <div className="absolute top-0 left-0 h-full w-full grid place-content-center bg-primary bg-opacity-80 rounded pointer-events-none">
                  <Spinner darkMode />
                </div>
              ) : null}

              <FkoRegistrationLandingForm
                errorMessage={errorMessage}
                onSubmitHandler={onSubmitHandler}
                getCachedUserExists={getCachedUserExists}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
});
ProgramsPromotionalLanding.displayName = 'ProgramsPromotionalLanding';
