import {useNavigate, useParams} from 'react-router-dom';
import {useProfiles} from 'api/User/useProfiles';
import {roleType} from 'constants/constants';
import TeamView from 'pages/Coach/Team/Team.view';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import {useMemo} from 'react';

const Team = () => {
  const {teamId} = useParams();
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isLoading} = useGetDirectorInfo(currentProfile?._id);
  const teamInfo = useMemo(
    () => directorInfo?.teams.find((team) => team._id === teamId),
    [teamId, directorInfo?.teams]
  );
  const teamDirectorName =
    `${currentProfile?.firstName ?? ''} ${currentProfile?.lastName ?? ''}`.trim() || currentProfile?.email || '-';
  const onClickInviteHandler = () => {
    navigate(`/director/teams/${teamInfo?._id}/invite`);
  };

  return (
    <TeamView
      onClickInviteHandler={onClickInviteHandler}
      team={teamInfo}
      loginUserName={teamDirectorName}
      loading={isLoading}
      role={roleType.director}
    />
  );
};

export default Team;
