import {useParams} from 'react-router-dom';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Assessment as AssessmentType} from 'api/Coach/useGetCoachInfo';
import {useProfiles} from 'api/User/useProfiles';
import Assessment from './Assessment/Assessment';
import Spinner from 'components/Loader/Spinner';
// import {Button} from 'components/Connects/Button';
import Dropdown, {DropdownOption} from 'components/Dropdown/Dropdown';
import {useGlobalActiveAssessmentState} from 'api/Director/useGlobalActiveAssessmentId';
import {useGlobalActiveTeamState} from 'api/Director/useGlobalActiveTeamId';
import {useGetDirectorInfo} from 'api/Director/useGetDirectorInfo';
import AssessmentStats from './AssessmentStats/AssessmentStats';

const DirectorHome = () => {
  const {teamId} = useParams();
  const {currentProfile} = useProfiles();
  const {data: directorInfo, isLoading} = useGetDirectorInfo(currentProfile?._id);
  const team = directorInfo?.teams.find((team) => team._id === teamId);
  const [currentAssessment, setCurrentAssessment] = useState<AssessmentType | null>(null);
  const {globalActiveAssessmentId, setGlobalActiveAssessmentId} = useGlobalActiveAssessmentState();
  const {globalActiveTeamId, setGlobalActiveTeamId} = useGlobalActiveTeamState();
  const [defaultAssessmentOption, setDefaultAssessmentOption] = useState<DropdownOption | null>(null);

  const {allAssessments, assessmentOptions} = useMemo(() => {
    const allAssessments: any = {};
    const assessmentOptions: any = [];
    if (team) {
      for (const currentAssessment of directorInfo?.teamAssessments?.[team?._id] ?? []) {
        allAssessments[currentAssessment?._id] = currentAssessment;
        assessmentOptions.push({displayValue: currentAssessment?.name, value: currentAssessment?._id});
      }
    }
    return {allAssessments, assessmentOptions};
  }, [directorInfo?.teamAssessments, team]);

  useEffect(() => {
    if (globalActiveAssessmentId || assessmentOptions.length) {
      let assessmentId = globalActiveAssessmentId;
      if (teamId && globalActiveTeamId !== teamId) {
        assessmentId = assessmentOptions[0]?.value;
        setGlobalActiveAssessmentId(assessmentId);
        setGlobalActiveTeamId(teamId);
      }
      setCurrentAssessment(allAssessments[assessmentId]);
      setDefaultAssessmentOption({displayValue: allAssessments[assessmentId]?.name, value: assessmentId});
    }
  }, [
    allAssessments,
    assessmentOptions,
    globalActiveAssessmentId,
    teamId,
    globalActiveTeamId,
    setGlobalActiveTeamId,
    setGlobalActiveAssessmentId,
  ]);

  const handleAssessmentChange = useCallback(
    (option: DropdownOption) => {
      const assessmentId = option?.value;
      setCurrentAssessment(allAssessments[assessmentId]);
      setGlobalActiveAssessmentId(assessmentId);
    },
    [allAssessments, setGlobalActiveAssessmentId]
  );

  return (
    <div className="flex-1 flex flex-col w-full">
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : !team ? (
        <div className="w-full h-full flex text-base mt-4 ml-4">Director is not part of this team.</div>
      ) : currentAssessment ? (
        <>
          <div className="flex mt-4">
            <Dropdown
              placeholder="Select Assessment"
              defaultOption={defaultAssessmentOption}
              options={assessmentOptions}
              onSelect={handleAssessmentChange}
            />
          </div>
          <AssessmentStats assessmentId={currentAssessment?._id ?? ''} />
          {/* <Button color="primary" variant="cta" text={'ASSIGN SESSIONS'} /> */}
          <Assessment assessment={currentAssessment} />
        </>
      ) : (
        <div className="w-full h-full flex text-base mt-4 ml-4">No Assessment found</div>
      )}
    </div>
  );
};

export default DirectorHome;
