import {ForgetPassword} from './pages/ForgetPassword/ForgetPassword';
import {Help} from './pages/Help/Help';
import {Home} from './pages/Home/Home';
import {SignInSignUp} from './pages/SignInSignUp/SignInSignUp';
import {SignUp} from './pages/SignUp/SignUp';
import {VerifyEmail} from './pages/VerifyEmail/VerifyEmail';
import {Profile} from './pages/Profile/Profile';
import {Helpdesk} from './pages/Helpdesk/Helpdesk';
import {Trainer} from './pages/Trainer/Trainer';
import Admin from './pages/Admin/Admin';
import AdminAnalyzeUser from './pages/Admin/AdminAnalyzeUser';
import {PlayersRoot} from './pages/RegistrationPlayers/PlayersRoot';
import {RegistrationLanding} from './pages/RegistrationLanding/RegistrationLanding';
import {RegistrationScheduling} from './pages/RegistrationScheduling/RegistrationScheduling';
import {FkoConfirmationPage} from './pages/Fko/FkoConfirmationPage';
import {FkoHelpForm} from './pages/Fko/FkoHelpForm/FkoHelpForm';
import {FkoRestart} from './pages/Fko/FkoRestart/FkoRestart';
import {LeaderboardList} from './pages/Leaderboards/LeaderboardList/LeaderboardList';
import {LeaderboardDetailsData} from './pages/Leaderboards/LeaderboardDetails/LeaderboardDetailsData';
import RootAuthenticatedLayout from './layouts/RootAuthenticatedLayout';
import RootAdminLayout from './layouts/RootAdminLayout';
import RootTrainerLayout from './layouts/RootTrainerLayout';
import NavbarLayout from './layouts/NavbarLayout';
import RootLayout from './layouts/RootLayout';
import {BookingSessionList} from './pages/Booking/SessionList/BookingSessionList';
import {BookingConfirmation} from './pages/Booking/Confirmation/BookingConfirmation';
import {BookingReview} from './pages/Booking/Review/BookingReview';
import {MyBookings} from './pages/Booking/MySessions/MyBookings';
import {Logout} from './pages/Logout/Logout';
import {TrainerDirectEmulation} from './pages/Trainer/TrainerDirectEmulation';
import {TrainerRewritePath} from './pages/Trainer/TrainerRewritePath';
import {Navigate, RouteObject} from 'react-router-dom';
import PlainLayout from 'layouts/PlainLayout';
import {ProgramsRoot} from 'pages/Programs/ProgramsRoot';
import {ProgramClassSchedulesList} from 'pages/Programs/ProgramClassSchedulesList';
import {ProgramClassScheduleDetails} from 'pages/Programs/ProgramClassScheduleDetails';
// import {ProgramEnrollmentsList} from 'pages/Programs/ProgramEnrollmentsList';
// import {ProgramEnrollmentDetails} from 'pages/Programs/ProgramEnrollmentDetails';
import {Connects} from 'pages/Connects/Connects';
import {NextWeek} from 'pages/Connects/NextWeek';
import {Schedule} from 'pages/Connects/Schedule';
import TrainerNavbarLayout from 'layouts/TrainerNavbarLayout';
import {NextSession} from 'pages/NextSession/NextSession';
import {AthleteCalendar} from 'pages/AthleteCalendar/AthleteCalendar';
import {ProgramCheckout} from 'pages/Programs/ProgramCheckout';
import ClassicLayout from 'layouts/ClassicLayout';
import {BaselineSessionInfo} from 'pages/Baseline/SessionInfo/BaselineSessionInfo';
import {BaselineBenchmarks} from 'pages/Baseline/Benchmarks/BaselineBenchmarks';
import {BaselineRetest} from 'pages/Baseline/Retest/BaselineRetest';
import {BaselineSessionData} from 'pages/Baseline/SessionData/BaselineSessionData';
import {ConnectsBaselineData} from 'pages/Connects/BaselineData';
import {CenterDiscovery} from 'pages/CenterDiscovery/CenterDiscovery';
import {DbatFkoConfirmationPage} from 'pages/Fko/DbatFkoConfirmationPage';
import {ConnectsFinish} from 'pages/Connects/ConnectsFinish';
import {DbatSportWrapper} from 'components/FKO/DbatSportWrapper';
import {TocaSportWrapper} from 'components/FKO/TocaSportWrapper';
import CoachDirectorLayout from 'layouts/CoachDirectorLayout';
import CoachTeam from 'pages/Coach/Team/Team';
import CoachInvite from 'pages/Coach/Invite/Invite';
import CoachEditTeam from 'pages/Coach/EditTeam/EditTeam';
import CoachPlayerAssessmentData from 'pages/Coach/PlayerAssessmentData/PlayerAssessmentData';
import CoachHome from 'pages/Coach/CoachHome';
import {TeamOnboarding} from 'pages/TeamOnboarding/TeamOnboarding';
import TeamOnboardingLayout from 'layouts/TeamOnboardingLayout';
import PlayerAssessmentBaselineData from 'pages/PlayerAssessmentBaselineData/PlayerAssessmentBaselineData';
import {TeamOnboardingSignUp} from 'pages/TeamOnboarding/TeamOnboardingSignUp';
import {PlayerAssessmentSportWrapper} from './components/FKO/PlayerAssessmentSportWrapper';
import {ProgramsPromotionalLanding} from 'pages/Programs/ProgramsPromotionalLanding';
import CoachNavbarLayout from 'layouts/CoachNavbarLayout';
import DirectorNavbarLayout from 'layouts/DirectorNavbarLayout';
import {roleType} from 'constants/constants';
import CoachTeams from 'pages/Coach/Teams/Teams';
import DirectorTeams from 'pages/Director/Teams/Teams';
import DirectorTeam from 'pages/Director/Team/Team';
import DirectorHome from 'pages/Director/DirectorHome';
import DirectorEditTeam from 'pages/Director/EditTeam/EditTeam';
import DirectorInvite from 'pages/Director/Invite/Invite';
import DirectorPlayerAssessmentData from 'pages/Director/PlayerAssessmentData/PlayerAssessmentData';
import {Coaches} from 'pages/Director/Coaches/Coaches';

export const routes: RouteObject[] = [
  // Direct trainer route as currently used by the native trainer app
  {
    path: '/player/:mboClientId/:siteId/overall',
    element: <TrainerRewritePath />,
  },
  {
    element: <RootLayout />,
    children: [
      // Authenticated but not authorized, so won't pass check in AuthContainer
      {
        element: <ClassicLayout />,
        children: [
          {
            path: 'verify-email',
            element: <VerifyEmail />,
          },
        ],
      },

      // PUBLIC ROUTES
      {
        element: <ClassicLayout />,
        children: [
          {
            path: '/auth',
            element: <SignInSignUp />,
          },
          {
            path: '/signup',
            element: <SignUp />,
          },
          {
            path: '/forget-password',
            element: <ForgetPassword />,
          },
          {
            path: '/helpdesk',
            element: <Helpdesk />,
          },
          // {
          //   path: '/leaderboards/:id',
          //   element: <LeaderboardDetailsData />,
          // },
        ], // END CLASSIC_LAYOUT
      },
      {
        element: <TeamOnboardingLayout />,
        path: '/team-onboarding',
        children: [
          {
            index: true,
            element: <TeamOnboarding />,
          },
          {
            path: 'signup',
            element: <TeamOnboardingSignUp />,
          },
        ],
      },
      {
        element: <PlainLayout />,
        children: [
          // NOTE: We use parent routes without `element` (which will make them use <Outlet/> as default element) and then
          //       define a child index route so that we can associate breadcrumb info. If we don't use parent routes
          //       without `element`, then each parent's element component would need to define <Outlet /> themselves.
          {
            path: '/:sessionId?/ftc',
            // handle: {
            //   crumb: () => ({name: 'Free Training Classes'}),
            // },
            children: [
              {
                index: true,
                element: <ProgramsPromotionalLanding />,
              },
              {
                path: 'help',
                element: <FkoHelpForm />,
              },
              {
                path: ':siteId',
                // handle: {
                //   crumb: (data: any) => ({name: data?.currentLocation?.name}),
                // },
                children: [
                  {
                    index: true,
                    element: <ProgramsRoot />,
                  },
                  {
                    path: ':programId',
                    handle: {
                      crumb: (data: any) => ({name: data?.program?.name}),
                    },
                    children: [
                      {
                        index: true,
                        element: <ProgramClassSchedulesList />,
                      },
                      {
                        path: ':enrollmentId', // TODO CLASSES eventually rename
                        handle: {
                          crumb: (data: any) => ({name: data?.enrollment?.name}),
                        },
                        children: [
                          {
                            index: true,
                            element: <ProgramClassScheduleDetails />,
                          },
                          {
                            path: 'register',
                            handle: {
                              crumb: () => ({name: 'Book Now'}),
                            },
                            element: <ProgramCheckout />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: '/programs',
            handle: {
              crumb: () => ({name: 'Programs'}),
            },
            children: [
              {
                index: true,
                element: <ProgramsRoot />,
              },
              {
                path: ':siteId',
                handle: {
                  crumb: (data: any) => ({name: data?.currentLocation?.name}),
                },
                children: [
                  {
                    index: true,
                    element: <ProgramsRoot />,
                  },
                  {
                    path: ':programId',
                    handle: {
                      crumb: (data: any) => ({name: data?.program?.name}),
                    },
                    children: [
                      // {
                      //   index: true,
                      //   element: <ProgramEnrollmentsList />,
                      // },
                      {
                        index: true,
                        element: <ProgramClassSchedulesList />,
                      },
                      {
                        path: ':enrollmentId',
                        handle: {
                          crumb: (data: any) => ({name: data?.enrollment?.name}),
                        },
                        // element: <ProgramEnrollmentDetails />,
                        children: [
                          {
                            index: true,
                            element: <ProgramClassScheduleDetails />,
                          },
                          {
                            path: 'register',
                            handle: {
                              crumb: () => ({name: 'Book Now'}),
                            },
                            // element: <RootAuthenticatedLayout />,
                            element: <ProgramCheckout />,
                            // children: [
                            //   {
                            //     index: true,
                            //     element: <ProgramCheckout />,
                            //   },
                            // ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: '/baseline-session-info',
            element: <BaselineSessionInfo />,
          },
          {
            path: '/baseline-benchmarks',
            element: <BaselineBenchmarks />,
          },
          {
            path: '/baseline-retest',
            element: <BaselineRetest />,
          },
          {
            path: '/leaderboards/:id',
            element: <LeaderboardDetailsData />,
          },
        ], // END PLAIN_LAYOUT
      },
      {
        // Center Discovery page that displays the nearest test centers based on the user's location.
        path: '/center-discovery',
        element: <CenterDiscovery />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        // Special TV variant that supports full screen layout
        path: '/tv/leaderboards/:id',
        element: <LeaderboardDetailsData />,
      },
      {
        element: <ClassicLayout />,
        children: [
          {
            path: '/:sessionId?/fko/',
            element: <TocaSportWrapper />,
            children: [
              {
                index: true,
                element: <RegistrationLanding />,
              },
              {
                path: 'help',
                element: <FkoHelpForm />,
              },
              {
                path: 'restart',
                element: <FkoRestart />,
              },
              {
                children: [
                  {
                    path: 'players',
                    element: <PlayersRoot />,
                  },
                  {
                    path: 'schedule',
                    element: <RegistrationScheduling />,
                  },
                  {
                    path: 'confirmation',
                    element: <FkoConfirmationPage />,
                  },
                ],
              },
            ],
          },
          {
            path: '/:sessionId?/dbat-fps/',
            element: <DbatSportWrapper />,
            children: [
              {
                index: true,
                element: <RegistrationLanding />,
              },
              {
                path: 'help',
                element: <FkoHelpForm />,
              },
              {
                path: 'restart',
                element: <FkoRestart />,
              },
              {
                children: [
                  {
                    path: 'players',
                    element: <PlayersRoot />,
                  },
                  {
                    path: 'schedule',
                    element: <RegistrationScheduling />,
                  },
                  {
                    path: 'confirmation',
                    element: <DbatFkoConfirmationPage />,
                  },
                ],
              },
            ],
          },
          {
            path: '/:sessionId?/player-assessment/',
            element: <PlayerAssessmentSportWrapper />,
            children: [
              {
                index: true,
                element: <RegistrationLanding />,
              },
              {
                path: 'help',
                element: <FkoHelpForm />,
              },
              {
                path: 'restart',
                element: <FkoRestart />,
              },
              {
                children: [
                  {
                    path: 'players',
                    element: <PlayersRoot />,
                  },
                  {
                    path: 'schedule',
                    element: <RegistrationScheduling />,
                  },
                  {
                    path: 'confirmation',
                    element: <FkoConfirmationPage />,
                  },
                ],
              },
            ],
          },
        ],
      },

      // PROTECTED ROUTES
      {
        path: '/emulation?/:siteId?/',
        element: <RootAuthenticatedLayout />,
        children: [
          {
            element: <NavbarLayout />,
            children: [
              {
                index: true,
                element: <Navigate to="/home" />,
              },
              {
                path: 'home',
                element: <Home />,
              },
              {
                path: 'nextsession',
                element: <NextSession />,
              },
              {
                path: 'upcoming',
                element: <AthleteCalendar />,
              },
              {
                path: 'book-now',
                element: <Navigate to="/bookings" />,
              },
              {
                path: 'bookings',
                children: [
                  {
                    index: true,
                    element: <BookingSessionList />,
                  },
                  {
                    path: 'sessions/:initialDate',
                    element: <BookingSessionList reselect />,
                  },
                  {
                    path: 'review',
                    element: <BookingReview />,
                  },
                  {
                    path: 'confirmation',
                    element: <BookingConfirmation />,
                  },
                ],
              },
              {
                path: 'enrollments',
                children: [
                  {
                    path: 'register/:siteId/:programId/:enrollmentId',
                    handle: {
                      // TODO Made this work for now but it would be better if we had this route nested with the
                      //      other routes that make up the breadcrumbs.
                      crumb: (data: any) => [
                        {name: 'Programs', path: '/programs'},
                        {name: data?.currentLocation?.name, path: `/programs/${data?.currentLocation?.siteId}`},
                        {
                          name: data?.program?.name,
                          path: `/programs/${data?.currentLocation?.siteId}/${data?.program?.id}`,
                        },
                        {
                          name: data?.enrollment?.name,
                          path: `/programs/${data?.currentLocation?.siteId}/${data?.program?.id}/${data?.enrollment?.id}`,
                        },
                        {name: 'Book Now'},
                      ],
                    },
                    element: <ProgramCheckout />,
                  },
                ],
              },
              {
                path: 'leaderboards',
                element: <LeaderboardList />,
              },
              {
                // Special TV variant that supports full screen layout
                path: 'tv/leaderboards',
                element: <LeaderboardList />,
              },
              {
                path: 'profile',
                element: <Profile />,
              },
              {
                path: 'mybookings',
                element: <MyBookings />,
              },
              {
                path: 'help',
                element: <Help />,
              },
              {
                path: 'baseline-session-data/:sessionDate',
                element: <BaselineSessionData />,
              },
              {
                path: 'assessment/baseline-session-data/:sessionDate',
                element: <PlayerAssessmentBaselineData />,
              },
            ] /* END NavbarLayout **/,
          },
          // {
          //   path: 'baseline-session-data/:sessionDate',
          //   element: <PlainLayout />,
          //   children: [
          //     {
          //       index: true,
          //       element: <BaselineSessionData />,
          //     },
          //   ],
          // },
          {
            path: 'trainer',
            element: <RootTrainerLayout />,
            children: [
              {
                // element: <ClassicLayout />,
                element: <TrainerNavbarLayout />,
                children: [
                  {
                    index: true,
                    element: <Trainer />,
                  },
                  {
                    path: 'profile',
                    element: <TrainerDirectEmulation />,
                  },
                ],
              },

              {
                path: 'connects',
                element: <TrainerNavbarLayout />,
                children: [
                  {
                    path: '?trainer=:trainerID?',
                    element: <Connects />,
                  },
                  {
                    path: 'baseline/?trainer=:trainerID?',
                    element: <ConnectsBaselineData />,
                  },
                  {
                    path: 'nextWeek/?trainer=:trainerID?',
                    element: <NextWeek />,
                  },
                  {
                    path: 'schedule/?trainer=:trainerID?',
                    element: <Schedule />,
                  },
                  {
                    path: 'finish/?trainer=:trainerID?',
                    element: <ConnectsFinish />,
                  },
                ],
              },
            ],
          },
          {
            path: 'admin',
            element: <RootAdminLayout />,
            children: [
              {
                index: true,
                element: <Admin />,
              },
              {
                path: 'analyze-user',
                element: <AdminAnalyzeUser />,
              },
            ],
          },
          {
            path: 'coach',
            element: <CoachDirectorLayout role={roleType.teamCoach} />,
            children: [
              {
                index: true,
                element: <CoachTeams />,
              },
              {
                element: <CoachNavbarLayout />,
                children: [
                  {
                    path: 'teams/:teamId',
                    children: [
                      {
                        index: true,
                        element: <CoachHome />,
                      },
                      {
                        path: 'players',
                        element: <CoachTeam />,
                      },
                      {
                        path: 'edit',
                        element: <CoachEditTeam />,
                      },
                      {
                        path: 'invite',
                        element: <CoachInvite />,
                      },
                      {
                        path: 'playerAssessmentData',
                        element: <CoachPlayerAssessmentData />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'director',
            element: <CoachDirectorLayout role={roleType.director} />,
            children: [
              {
                index: true,
                element: <DirectorTeams />,
              },
              {
                element: <DirectorNavbarLayout />,
                children: [
                  {
                    path: 'teams/:teamId',
                    children: [
                      {
                        index: true,
                        element: <DirectorHome />,
                      },
                      {
                        path: 'players',
                        element: <DirectorTeam />,
                      },
                      {
                        path: 'edit',
                        element: <DirectorEditTeam />,
                      },
                      {
                        path: 'invite',
                        element: <DirectorInvite />,
                      },
                      {
                        path: 'playerAssessmentData',
                        element: <DirectorPlayerAssessmentData />,
                      },
                      {
                        path: 'coaches',
                        element: <Coaches />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
