import {memo, ReactNode, useMemo, useState} from 'react';
import {ReactComponent as Chevron} from '../../../assets/img/icons/redesign-icons/chevron-down.svg';
import Toggle from 'components/Toggle/Toggle';
import {ExerciseSummary, PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';
import {baselineBenchmarkValues, BenchmarkEntry, isBaselineLevel} from 'pages/Connects/utils';

export const PlayerAssessmentConnectsMobileBaselineSessionTable = memo(
  ({
    sessionData,
    toggleSessionSelection,
    enableSessionSelection = false,
    selectedSessionIndexes = [],
  }: {
    sessionData?: PlayerSessionSummary;
    toggleSessionSelection?: (sessionIndex: number) => void;
    enableSessionSelection?: boolean;
    selectedSessionIndexes: number[];
  }) => {
    if (!sessionData || !isBaselineLevel(sessionData.baselineLevel)) {
      return null;
    }

    const baselineLevelBenchmark = baselineBenchmarkValues?.[sessionData.baselineLevel];
    const exercises = sessionData.exerciseSummary;

    return (
      <>
        {exercises?.map((exercise, i) => {
          const showExercise = enableSessionSelection || !!exercise.confirmedBy;
          const benchmark = baselineLevelBenchmark[exercise.name] || {};
          return showExercise ? (
            <_ExerciseSection
              key={`benchmark_${i}`}
              benchmark={benchmark}
              exercise={exercise}
              defaultOpen={true}
              exerciseIndex={i}
              enableSessionSelection={enableSessionSelection}
              toggleSessionSelection={toggleSessionSelection}
              selectedSessionIndexes={selectedSessionIndexes}
              exercises={exercises}
            />
          ) : null;
        })}
      </>
    );
  }
);
PlayerAssessmentConnectsMobileBaselineSessionTable.displayName = 'ConnectsMobileBaselineSessionTable';

const _ExerciseSection = ({
  benchmark,
  exercise,
  defaultOpen = false,
  exerciseIndex,
  toggleSessionSelection,
  enableSessionSelection = false,
  selectedSessionIndexes,
  exercises,
}: {
  benchmark: BenchmarkEntry;
  exercise: ExerciseSummary;
  defaultOpen?: boolean;
  exerciseIndex: number;
  toggleSessionSelection?: (sessionIndex: number) => void;
  enableSessionSelection?: boolean;
  selectedSessionIndexes: number[];
  exercises: ExerciseSummary[];
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  return (
    <div
      className={`${
        isExpanded ? 'max-h-[600px]' : 'max-h-[48px]'
      } w-full overflow-hidden transition-all border border-grey-light border-b-0 last-of-type:border-b lg:last-of-type:rounded-b`}
    >
      <button
        onClick={() => setIsExpanded((prev) => !prev)}
        className="h-[48px] w-full px-6 py-4 bg-white flex justify-end items-center"
      >
        <span className="grow text-base text-left text-tertiary font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
          {exercise.name}
        </span>
        <Chevron className={`${isExpanded ? 'rotate-180' : ''} w-4 h-4 fill-grey-xdark opacity-75 transition-all`} />
      </button>

      <div className="border-t border-grey-light">
        <_SessionTable
          benchmark={benchmark}
          exercise={exercise}
          exerciseIndex={exerciseIndex}
          toggleSessionSelection={toggleSessionSelection}
          enableSessionSelection={enableSessionSelection}
          selectedSessionIndexes={selectedSessionIndexes}
          exercises={exercises}
        />
      </div>
    </div>
  );
};

const _SessionTable = memo(
  ({
    benchmark,
    exercise,
    exerciseIndex,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleSessionSelection = () => {},
    enableSessionSelection,
    selectedSessionIndexes,
    exercises,
  }: {
    benchmark: BenchmarkEntry;
    exercise: ExerciseSummary;
    exerciseIndex: number;
    toggleSessionSelection?: (sessionIndex: number) => void;
    enableSessionSelection: boolean;
    selectedSessionIndexes: number[];
    exercises: ExerciseSummary[];
  }) => {
    // Indexes of exercises that are similar to the current exercise
    const similarExerciseIndexes = useMemo(() => {
      return exercises.reduce((acc, currentExercise, currExerciseIndex) => {
        if (exerciseIndex !== currExerciseIndex && currentExercise.name === exercise.name) {
          acc.push(currExerciseIndex);
        }
        return acc;
      }, [] as number[]);
    }, [exercises, exercise, exerciseIndex]);

    // Check if any of the similar exercises are selected
    const isSimilarExerciseSelected = useMemo(
      () => similarExerciseIndexes.some((index) => selectedSessionIndexes.includes(index)),
      [similarExerciseIndexes, selectedSessionIndexes]
    );

    const isToggledOn = !!exercise?.confirmedBy || selectedSessionIndexes.includes(exerciseIndex);

    return (
      <>
        {enableSessionSelection && (
          <div className="flex items-center ml-4 mt-4 mb-4 font-light">
            <label htmlFor="toggle-exercise" className="mr-4 text-lg">
              Select Exercise
            </label>
            <Toggle
              isDisabled={isSimilarExerciseSelected}
              id="toggle-exercise"
              isOn={isToggledOn}
              onToggle={() => toggleSessionSelection(exerciseIndex)}
            />
            {isSimilarExerciseSelected && (
              <div className="ml-2 text-xs">
                *{exercise.name} is already selected. Please deselect it to select this exercise.
              </div>
            )}
          </div>
        )}

        <table className="grid grid-cols-[minmax(min-content,2fr),minmax(min-content,1fr),minmax(min-content,2fr),minmax(min-content,1fr)]">
          <thead className="contents">
            <tr className="contents text-xs sm:text-sm tracking-[-0.25px] text-primary">
              <th className="font-semibold bg-tertiary bg-opacity-20">
                <_Cell>Target Streak</_Cell>
              </th>
              <th className="font-normal bg-white">
                <_Cell>Streak</_Cell>
              </th>
              <th className="font-semibold bg-tertiary bg-opacity-20">
                <_Cell>Target Accuracy</_Cell>
              </th>
              <th className="font-normal bg-white">
                <_Cell>Accuracy</_Cell>
              </th>
              <th className="row-start-3 font-semibold bg-tertiary bg-opacity-20">
                <_Cell>Target SOP</_Cell>
              </th>
              <th className="row-start-3 font-normal bg-white">
                <_Cell>SOP</_Cell>
              </th>
              <th className="row-start-3 font-semibold bg-tertiary bg-opacity-20">
                <_Cell>Target MPH</_Cell>
              </th>
              <th className="row-start-3 font-normal bg-white">
                <_Cell>MPH</_Cell>
              </th>
            </tr>
          </thead>

          <tbody className="contents">
            <tr className="contents text-sm sm:text-base text-primary">
              <td className="bg-tertiary bg-opacity-25">
                <_Cell>{benchmark.targetStreak}</_Cell>
              </td>
              <td className="bg-grey-xlight">
                <_Cell>{exercise?.streak ?? '-'}</_Cell>
              </td>
              <td className="bg-tertiary bg-opacity-25">
                <_Cell>{benchmark.targetAccuracy ? `${benchmark.targetAccuracy * 100}%` : ''}</_Cell>
              </td>
              <td className="bg-grey-xlight">
                <_Cell>{exercise?.accuracy ? `${(exercise.accuracy * 100)?.toFixed(2)}%` : '-'}</_Cell>
              </td>
              <td className="row-start-4 bg-tertiary bg-opacity-25">
                <_Cell>{benchmark.targetSOP}</_Cell>
              </td>
              <td className="row-start-4 bg-grey-xlight">
                <_Cell>{exercise?.speedOfPlay && exercise.speedOfPlay < 99999 ? exercise.speedOfPlay : '-'}</_Cell>
              </td>
              <td className="row-start-4 bg-tertiary bg-opacity-25">
                <_Cell>{benchmark.targetMph}</_Cell>
              </td>
              <td className="row-start-4 bg-grey-xlight">
                <_Cell>{exercise?.mph ?? '-'}</_Cell>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
);
_SessionTable.displayName = '_SessionTable';

const _Cell = ({children}: {children: ReactNode}) => {
  return <p className="h-[48px] min-h-[48px] grid place-content-center uppercase p-1">{children}</p>;
};
