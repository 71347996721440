import {PlayerAssessmentConnectsBaselineSessionData} from 'components/Connects/PlayerAssessmentBaselineSessionData/PlayerAssessmentConnectsBaselineSessionData';
import Spinner from 'components/Loader/Spinner';
import {Button} from 'components/Connects/Button';
import {PlayerSessionSummary} from 'api/Trainer/useGetPlayerLastSessionSummary';

interface PlayerAssessmentDataViewProps {
  isLoading: boolean;
  playerAssessmentData: PlayerSessionSummary;
  isSessionSelectionCompleted: boolean;
  date: string;
  onClickDownloadHandler: () => void;
}

const PlayerAssessmentDataView = ({
  isLoading,
  playerAssessmentData,
  isSessionSelectionCompleted,
  date,
  onClickDownloadHandler,
}: PlayerAssessmentDataViewProps) => {
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      ) : playerAssessmentData ? (
        <div className="grow flex flex-col">
          <div className="text-5xl text-primary text-center leading-[60px] font-semibold mt-10">
            PLAYER ASSESSMENT DATA
          </div>

          {playerAssessmentData?.isBaseline && isSessionSelectionCompleted ? (
            <>
              <div className="exceed-global-padding lg:max-w-[800px] lg:mx-auto lg:w-full">
                <PlayerAssessmentConnectsBaselineSessionData
                  sessionSummary={playerAssessmentData}
                  enableSessionSelection={false}
                  date={date}
                />
              </div>
              <div className="exceed-global-padding p-6 mt-2 border-2 border-grey" onClick={onClickDownloadHandler}>
                <Button color="secondary" variant="cta" text="Download XLS" />
              </div>
            </>
          ) : (
            <div className="grid place-content-center">
              <p className="text-sm text-grey-xdark">Player assessment data not found</p>
            </div>
          )}
        </div>
      ) : (
        <div className="grow flex flex-col">
          <p className="text-sm text-grey-xdark text-center p-4">unable to load player assessment data</p>
        </div>
      )}
    </>
  );
};

export default PlayerAssessmentDataView;
