import SegmentedProgressCircle from 'components/ProgressBar/SegmentedProgressCircle/SegmentedProgressCircle';

interface AssessmentStatsViewProps {
  totalPlayers: number;
  assignedSessions: number;
  completedSessions: number;
  yetToComplete: number;
}

const AssessmentStatsView = ({
  totalPlayers,
  assignedSessions,
  completedSessions,
  yetToComplete,
}: AssessmentStatsViewProps) => (
  <div className="stats-linear-gradient h-40 rounded mt-4 flex items-center justify-center text-white mb-2">
    <div className="flex flex-col items-center justify-center">
      <span className="text-sm font-semibold mb-2">Sessions Assigned</span>
      <SegmentedProgressCircle r={40} maxValue={totalPlayers} value={assignedSessions} />
      <span>Out of {totalPlayers} available</span>
    </div>
    <div className="h-full bg-line inline-block w-[1px] mt-2 mb-2 ml-6 mr-6"></div>
    <div className="flex flex-col items-center justify-center">
      <span className="text-sm font-semibold mb-2">Sessions Completed</span>
      <SegmentedProgressCircle r={40} maxValue={totalPlayers} value={completedSessions} />
      <span>
        {yetToComplete} player{yetToComplete > 1 ? 's' : ''} not completed
      </span>
    </div>
  </div>
);

export default AssessmentStatsView;
