import {sortObjectsByStringProperty} from '../utils/utils';

export interface TocaLocation {
  devSite?: boolean;
  siteId: string;
  key: string;
  name: string;
  hubspotName?: string; // https://app.hubspot.com/property-settings/6951339/properties?type=0-1&property=locations__drop_down_&action=edit
  region: string;
  timezone: string;
  utcOffset: number;
  dstOffset: number;
  coordinates: {
    lat: number;
    lon: number;
  };
  state: string;
  country: string;
  phoneNumber: string;
  email: string;
  address: string;
  logo: string;
  centerUrl: string;
  fkoAppointmentsStartDaysFromToday?: number;
  fkoAppointmentsBookingWindowInDays?: number;
  fkoDailyStartTime?: {hour: number; minute: number};
  fkoDailyEndTime?: {hour: number; minute: number};
  fkoApplyDailyTimeRestrictions?: boolean;
  fkoCenterName?: string;
  fkoSupported: boolean;
  dbatCenterName?: string;
  dbatSupported?: boolean;
  playerAssessmentSupported?: boolean;
  classesSupported?: boolean;
  classesFreeTrialEnabled?: boolean;
  classesStartDaysFromToday?: number;
  classesBookingWindowInDays?: number;
}

const EASTERN_DST_OFFSET = -4;
const EASTERN_UTC_OFFSET = -5;
const CENTRAL_DST_OFFSET = -5;
const CENTRAL_UTC_OFFSET = -6;
const MOUNTAIN_DST_OFFSET = -6;
const MOUNTAIN_UTC_OFFSET = -7;
const WESTERN_DST_OFFSET = -7;
const WESTERN_UTC_OFFSET = -8;

const TOCA_REGION = 'TOCA';
const DBAT_TOCA_REGION = 'DBAT-TOCA';

const includeSandbox = process.env.REACT_APP_INCLUDE_SANDBOX_LOCATION === 'true';

export const DEFAULT_FKO_APPT_START_DAYS_FROM_TODAY = 1;
export const DEFAULT_FKO_BOOKING_WINDOW = 14;
export const DEFAULT_FKO_DAILY_START_TIME = {hour: 10, minute: 0};
export const DEFAULT_FKO_DAILY_END_TIME = {hour: 16, minute: 0};

// Portal Sandbox MBO Site
export const SandboxLocation: TocaLocation = {
  devSite: true,
  siteId: '472698',
  key: 'sandbox',
  name: 'Sandbox',
  state: 'CA',
  country: 'US',
  phoneNumber: '(555) 555-5555',
  region: TOCA_REGION,
  timezone: 'America/Los_Angeles',
  utcOffset: WESTERN_UTC_OFFSET,
  dstOffset: WESTERN_DST_OFFSET,
  coordinates: {
    lat: 35.95053142518453,
    lon: -86.82556057967852,
  },
  email: 'sandbox@tocafootball.com',
  address: 'Sandbox address',
  logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644fd987203e295d6067ea5e_costa%20(1)%20(1)%20(1).jpg',
  centerUrl: 'https://www.tocafootball.com/centers/costamesa',
  fkoSupported: true,
  dbatSupported: true,
  playerAssessmentSupported: true,
  classesSupported: true,
  classesFreeTrialEnabled: true,
  fkoAppointmentsStartDaysFromToday: 0, // Allow same-day appointments for player assessments
};

// QA MBO Site
export const QALocation: TocaLocation = {
  devSite: true,
  siteId: '-2147482213',
  key: 'preprod',
  name: 'Pre-Prod',
  state: 'CA',
  country: 'US',
  phoneNumber: '(555) 555-5555',
  region: TOCA_REGION,
  timezone: 'America/Los_Angeles',
  utcOffset: WESTERN_UTC_OFFSET,
  dstOffset: WESTERN_DST_OFFSET,
  coordinates: {
    lat: 35.95053142518453,
    lon: -86.82556057967852,
  },
  email: 'qa@tocafootball.com',
  address: 'QA address',
  logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644fd987203e295d6067ea5e_costa%20(1)%20(1)%20(1).jpg',
  centerUrl: 'https://www.tocafootball.com/centers/costamesa',
  fkoSupported: true,
  playerAssessmentSupported: true,
  classesSupported: false,
  classesFreeTrialEnabled: false,
};

// TOCA Experience Staging MBO Site
export const TrainerAppSandboxLocation: TocaLocation = {
  devSite: true,
  siteId: '-2147482215',
  key: 'trainerAppSandbox',
  name: 'Trainer App Sandbox',
  state: 'CA',
  country: 'US',
  phoneNumber: '(555) 555-5555',
  region: TOCA_REGION,
  timezone: 'America/Los_Angeles',
  utcOffset: WESTERN_UTC_OFFSET,
  dstOffset: WESTERN_DST_OFFSET,
  coordinates: {
    lat: 35.95053142518453,
    lon: -86.82556057967852,
  },
  email: 'trainerSandbox@tocafootball.com',
  address: 'Trainer sandbox address',
  logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644fd987203e295d6067ea5e_costa%20(1)%20(1)%20(1).jpg',
  centerUrl: 'https://www.tocafootball.com/centers/costamesa',
  fkoSupported: true,
  playerAssessmentSupported: true,
  classesSupported: false,
  classesFreeTrialEnabled: false,
};

// ---all locations with an mboSiteId---
export const tocaLocations: TocaLocation[] = [
  ...(includeSandbox ? [SandboxLocation, QALocation, TrainerAppSandboxLocation] : []),
  ...[
    {
      siteId: '195492',
      key: 'costamesa',
      name: 'Costa Mesa',
      state: 'CA',
      country: 'US',
      phoneNumber: '(714) 361-8990',
      region: TOCA_REGION,
      timezone: 'America/Los_Angeles',
      utcOffset: WESTERN_UTC_OFFSET,
      dstOffset: WESTERN_DST_OFFSET,
      hubspotName: 'Costa Mesa',
      coordinates: {
        lat: 33.67418565216646,
        lon: -117.88954954477214,
      },
      email: 'costamesa@tocafootball.com',
      address: '2777 Bristol Street Costa Mesa, CA 92626',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644fd987203e295d6067ea5e_costa%20(1)%20(1)%20(1).jpg',
      centerUrl: 'https://www.tocafootball.com/centers/costamesa',
      fkoSupported: true,
      playerAssessmentSupported: false,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '437964',
      key: 'redmond',
      name: 'Redmond',
      state: 'WA',
      country: 'US',
      phoneNumber: '(425) 298-0294',
      region: TOCA_REGION,
      timezone: 'America/Los_Angeles',
      utcOffset: WESTERN_UTC_OFFSET,
      dstOffset: WESTERN_DST_OFFSET,
      hubspotName: 'Redmond',
      coordinates: {
        lat: 47.68709893293232,
        lon: -122.14528560168414,
      },
      email: 'redmond@tocafootball.com',
      address: '14624 Northeast 95th Street Redmond, WA 98052',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644fdaebd017b26a9d58205a_632e7a7182945352fce9d659_IMG_4796%20(1)%20(1).jpg',
      centerUrl: 'https://www.tocafootball.com/centers/redmond',
      fkoSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '876801',
      key: 'langley',
      name: 'Langley',
      state: 'BC',
      country: 'CA',
      phoneNumber: '(778) 312-3022',
      region: TOCA_REGION,
      timezone: 'America/Vancouver',
      utcOffset: WESTERN_UTC_OFFSET,
      dstOffset: WESTERN_DST_OFFSET,
      hubspotName: 'Langley',
      coordinates: {
        lat: 49.172096681865895,
        lon: -122.66588516610238,
      },
      email: 'langley@toca.ca',
      address: '9339 200A Street, Langley City, British Columbia V1M 3Y4',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644c40d12b7a88cf64694256_632e7bcd6f7c928e3344f53f_20200217_115635%20(1).jpg',
      centerUrl: 'https://www.tocafootball.com/centers/langley',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '3720',
      key: 'mansfield',
      name: 'Mansfield',
      state: 'TX',
      country: 'US',
      phoneNumber: '(817) 539-9933',
      region: DBAT_TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Mansfield',
      fkoAppointmentsBookingWindowInDays: 21,
      coordinates: {
        lat: 32.54892222089283,
        lon: -97.13715137166791,
      },
      email: 'mansfield@tocafootball.com',
      address: '201 Sentry Drive Mansfield, TX 76063',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/632e7cc82bfb6a33b345cdc0_DSC_7918-5.jpg',
      centerUrl: 'https://www.tocafootball.com/centers/mansfield',
      fkoSupported: true,
      dbatSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '5720010',
      key: 'nashville',
      name: 'Nashville',
      state: 'TN',
      country: 'US',
      phoneNumber: '(629) 235-7373',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Nashville',
      coordinates: {
        lat: 35.95053142518453,
        lon: -86.82556057967852,
      },
      email: 'nashville@tocafootball.com',
      address: '215 Gothic Court Franklin, TN 37067',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644c53671887d1e27fec6e0e_617776a9165ab2f93262c65f_nashville.jpg',
      centerUrl: 'https://www.tocafootball.com/centers/nashville',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
      playerAssessmentSupported: true,
    },
    {
      siteId: '616713',
      key: 'perimeter',
      name: 'Perimeter',
      state: 'GA',
      country: 'US',
      phoneNumber: '(678) 459-2457',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Perimeter',
      coordinates: {
        lat: 33.93072170138399,
        lon: -84.26046438330337,
      },
      email: 'perimeter@tocafootball.com',
      address: '3039 Amwiler Road, Atlanta, GA 30360',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/632e7cfa7546551c4d8ee6d0_DSC_7933-10.jpg',
      centerUrl: 'https://www.tocafootball.com/centers/perimeter',
      fkoSupported: true,
      dbatSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '528224',
      key: 'lynnwood',
      name: 'Lynnwood',
      state: 'WA',
      country: 'US',
      phoneNumber: '(425) 921-6843',
      region: TOCA_REGION,
      timezone: 'America/Los_Angeles',
      utcOffset: WESTERN_UTC_OFFSET,
      dstOffset: WESTERN_DST_OFFSET,
      hubspotName: 'Lynnwood',
      fkoAppointmentsStartDaysFromToday: 14,
      fkoAppointmentsBookingWindowInDays: 14,
      coordinates: {
        lat: 47.835359466059046,
        lon: -122.26866664805496,
      },

      email: 'lynnwood@tocafootball.com',
      address: '18021 Alderwood Mall Parkway Lynnwood, WA 98037',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/619415669712043626f6daec_lynnwood.jpg',
      centerUrl: 'https://www.tocafootball.com/centers/lynnwood',
      fkoSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '616916',
      key: 'naperville',
      name: 'Naperville',
      state: 'IL',
      country: 'US',
      phoneNumber: '(630) 470-6400',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Naperville',
      coordinates: {
        lat: 41.773897631921926,
        lon: -88.19397622512611,
      },
      email: 'naperville@tocafootball.com',
      address: '1740 Quincy Avenue Naperville, IL 60540',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/619574cb5aad4449193e0666_naperville.jpg',
      centerUrl: 'https://www.tocafootball.com/centers/naperville',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '764970',
      key: 'northvan',
      name: 'North Vancouver',
      state: 'BC',
      country: 'CA',
      phoneNumber: '(604) 354-8668',
      region: TOCA_REGION,
      timezone: 'America/Vancouver',
      utcOffset: WESTERN_UTC_OFFSET,
      dstOffset: WESTERN_DST_OFFSET,
      hubspotName: 'North Vancouver',
      coordinates: {
        lat: 49.30067983295598,
        lon: -123.02873582192011,
      },
      email: 'canada@tocafootball.com',
      address: '20 Orwell Street, North Vancouver, British Columbia V7J 2G1',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644c4dd02c78b4c64d894a6d_632e7bee2bfb6ae40345c925_IMG_8112%20(1)%20(1).jpg',
      centerUrl: 'https://www.tocafootball.com/centers/northvancouver',
      fkoSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '276683',
      key: 'johnscreek',
      name: 'Johns Creek',
      state: 'GA',
      country: 'US',
      phoneNumber: '(770) 813-3333',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Johns Creek',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/632e7ce79ea73ba7b42308dd_DSC_7925-7-p-800.jpg',
      coordinates: {
        lat: 34.068181976265,
        lon: -84.15637038094381,
      },
      email: 'johnscreek@tocafootball.com',
      address: '3985 Lakefield Court Suwanee, GA 30024',
      centerUrl: 'https://www.tocafootball.com/centers/johnscreek',
      fkoSupported: true,
      dbatSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '276364',
      key: 'marietta',
      name: 'Marietta',
      state: 'GA',
      country: 'US',
      phoneNumber: '(678) 403-2632',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Marietta',
      coordinates: {
        lat: 33.954221348361855,
        lon: -84.50325620506116,
      },
      email: 'marietta@tocafootball.com',
      address: '1666 Roswell Road Marietta, GA 30062',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/632e7d40754655ae438eefd3_DSC_8202-18.jpg',
      centerUrl: 'https://www.tocafootball.com/centers/marietta',
      fkoSupported: true,
      dbatSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '614203',
      key: 'westcobb',
      name: 'West Cobb',
      state: 'GA',
      country: 'US',
      phoneNumber: '(678) 496-7777',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'West Cobb',
      fkoAppointmentsBookingWindowInDays: 21,
      coordinates: {
        lat: 34.02888960282496,
        lon: -84.64196918515853,
      },
      email: 'westcobb@tocafootball.com',
      address: '3246 Odeon Way, Kennesaw, GA 30144',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644fdc684824899ed496e0d9_632e7ca091252de064e53590_DSC_7908-3%20(1).jpg',
      centerUrl: 'https://www.tocafootball.com/centers/westcobb',
      fkoSupported: true,
      dbatSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '5722524',
      key: 'denver',
      name: 'Denver',
      state: 'CO',
      country: 'US',
      phoneNumber: '(303) 320-3033',
      region: TOCA_REGION,
      timezone: 'America/Denver',
      utcOffset: MOUNTAIN_UTC_OFFSET,
      dstOffset: MOUNTAIN_DST_OFFSET,
      hubspotName: 'Denver',
      coordinates: {
        lat: 39.753705273375836,
        lon: -104.88742411912264,
      },
      email: 'denver@tocafootball.com',
      address: '2400 Central Park Boulevard Denver, CO 80238',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/61410a3509bc742b1a7606ca_bladium.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/denver',
      fkoSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '5724509',
      key: 'madison',
      name: 'Madison',
      state: 'WI',
      country: 'US',
      phoneNumber: '(608) 288-9600',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Madison',
      coordinates: {
        lat: 43.01255392709303,
        lon: -89.45913627967855,
      },
      email: 'madison@tocafootball.com',
      address: '5964 Executive Drive Fitchburg, WI 53719',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644c3d1c85ab152d84125974_632e7e16c5e7a6673a717460_image11.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/madison',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5729555',
      key: 'columbus',
      name: 'Columbus',
      state: 'OH',
      country: 'US',
      phoneNumber: '(740) 657-8888',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Columbus',
      coordinates: {
        lat: 40.18180574486627,
        lon: -83.01848198886893,
      },
      email: 'columbus@tocafootball.com',
      address: '409 Orange Point Drive, Lewis Center, OH 43035',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/632e7e16c5e7a6673a717460_image11.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/columbus',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5730351',
      key: 'thecolony',
      name: 'The Colony',
      state: 'TX',
      country: 'US',
      phoneNumber: '(469) 384-3400',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'The Colony',
      coordinates: {
        lat: 33.11329547550682,
        lon: -96.89231633978778,
      },
      email: 'thecolony@tocafootball.com',
      address: '7801 Main Street The Colony, TX 75056',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644c3d1c85ab152d84125974_632e7e16c5e7a6673a717460_image11.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/thecolony',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5730352',
      key: 'keller',
      name: 'Keller',
      state: 'TX',
      country: 'US',
      phoneNumber: '(817) 379-5805',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Keller',
      coordinates: {
        lat: 32.93440373736239,
        lon: -97.25974953811233,
      },
      email: 'keller@tocafootball.com',
      address: '331 Golden Triangle Boulevard Keller, TX 76248',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/632e7e16c5e7a6673a717460_image11.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/keller',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5730350',
      key: 'allen',
      name: 'Allen',
      state: 'TX',
      country: 'US',
      phoneNumber: '(214) 644-2070',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Allen',
      coordinates: {
        lat: 33.09935196480422,
        lon: -96.64702019793052,
      },
      email: 'allen@tocafootball.com',
      address: '950 East Main Street Allen, TX 75002',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/641b25b7073f7a2eac0242e8_Junior-field-e1541817239379.jpg',
      centerUrl: 'https://www.tocafootball.com/centers/allen',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5730353',
      key: 'carrollton',
      name: 'Carrollton',
      state: 'TX',
      country: 'US',
      phoneNumber: '(469) 521-9691',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Carrollton',
      coordinates: {
        lat: 32.966861726245426,
        lon: -96.93475406424719,
      },
      email: 'carrollton@tocafootball.com',
      address: '1850 Legends Trail Carrollton, TX 75006',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/644c3d1c85ab152d84125974_632e7e16c5e7a6673a717460_image11.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/carrollton',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5732519',
      key: 'loganville',
      name: 'Loganville',
      state: 'GA',
      country: 'US',
      phoneNumber: '(770) 740 7250',
      region: TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Loganville',
      coordinates: {
        lat: 33.855937128958615,
        lon: -83.93251659308366,
      },
      email: 'loganville@tocafootball.com',
      address: '3237 Krisam Creek Dr, Loganville, GA 30052',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/645d24166df7a8cfbfcca6d5_641b25b7073f7a2eac0242e8_Junior-field-e1541817239379.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/loganville',
      fkoSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '5733625',
      key: 'richmond',
      name: 'Richmond-Bedford Heights',
      state: 'OH',
      country: 'US',
      phoneNumber: '(216) 464-7122',
      region: TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Richmond',
      coordinates: {
        lat: 41.415568130936514,
        lon: -81.48578971887643,
      },
      email: 'richmond@tocafootball.com',
      address: '26555 Richmond Rd, Bedford Heights, OH 44146',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/645d24166df7a8cfbfcca6d5_641b25b7073f7a2eac0242e8_Junior-field-e1541817239379.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/richmondbedfordheights',
      fkoSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '5735385',
      key: 'wixom',
      name: 'Wixom',
      state: 'MI',
      country: 'US',
      phoneNumber: '(248) 669-9817',
      region: TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Wixom',
      coordinates: {
        lat: 42.520052122153125,
        lon: -83.53426904553307,
      },
      email: 'wixom@tocafootball.com',
      address: '30990 South Wixom Road, Wixom, MI 48393',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/645d24166df7a8cfbfcca6d5_641b25b7073f7a2eac0242e8_Junior-field-e1541817239379.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/wixom',
      fkoSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '5737305',
      key: 'st.louis',
      name: 'St. Louis',
      state: 'MO',
      country: 'US',
      phoneNumber: '+1 314 574 4157',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'St. Louis',
      coordinates: {
        lat: 38.62513598283266,
        lon: -90.26654555767148,
      },
      email: 'stlouis@tocafootball.com',
      address: '4900 Manchester Ave Suite 500, St. Louis, MO 63110',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
      centerUrl: 'https://www.tocafootball.com/centers/stlouis',
      fkoSupported: true,
      playerAssessmentSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5736811',
      key: 'burnsville',
      name: 'Burnsville',
      state: 'MN',
      country: 'US',
      phoneNumber: '+1 651 564 5813',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Burnsville',
      coordinates: {
        lat: 44.73977543939128,
        lon: -93.32584965767148,
      },
      email: 'Burnsville@tocafootball.com',
      address: '3601 W. 145th St. Burnsville, MN 55306',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/6500a6cf870531f080608dd9_adult-league-banner%20(1).jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/burnsville',
      fkoSupported: true,
      dbatSupported: false,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5737697',
      key: 'evanston',
      name: 'Evanston',
      state: 'IL',
      country: 'US',
      phoneNumber: '+1 847 864 0995',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Evanston',
      coordinates: {
        lat: 42.0250343801452,
        lon: -87.70772073159938,
      },
      email: 'Evanston@tocafootball.com',
      address: '2454 Oakton St, Evanston, IL 60202',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/6500a6cf870531f080608dd9_adult-league-banner%20(1).jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/evanston',
      fkoSupported: true,
      classesSupported: true,
      classesFreeTrialEnabled: true,
    },
    {
      siteId: '5733380',
      key: 'fcdallas',
      name: 'FC Dallas',
      state: 'TX',
      country: 'US',
      phoneNumber: '+1 469 365 0081',
      region: TOCA_REGION,
      timezone: 'America/Chicago',
      utcOffset: CENTRAL_UTC_OFFSET,
      dstOffset: CENTRAL_DST_OFFSET,
      hubspotName: 'Dallas',
      coordinates: {
        lat: 33.16080598570308,
        lon: -96.82785218940639,
      },
      email: 'fcdallas@tocafootball.com',
      address: '9966 John W. Elliott Dr, Frisco, TX 75033',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/6500a6cf870531f080608dd9_adult-league-banner%20(1).jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/fcdallas',
      fkoSupported: false,
      playerAssessmentSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
      fkoAppointmentsStartDaysFromToday: 0,
    },
    {
      siteId: '24265',
      key: 'buckhead',
      name: 'Buckhead',
      state: 'GA',
      country: 'US',
      phoneNumber: '+1 404 355 9700',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Buckhead',
      coordinates: {
        lat: 33.819477393835875,
        lon: -84.45266620694848,
      },
      email: '',
      address: '2345 Adams Dr NW, Atlanta, GA 30318',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
      centerUrl: 'https://www.tocafootball.com/centers/buckhead',
      fkoSupported: false,
      dbatSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '432803',
      key: 'indianTrail',
      name: 'Indian Trail',
      state: 'NC',
      country: 'US',
      phoneNumber: '+1 980 368 3228',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Indian Trail',
      coordinates: {
        lat: 42.520052122153125,
        lon: -83.53426904553307,
      },
      email: '',
      address: '13707 E Independence Blvd, Indian Trail, NC 28079',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/645d24166df7a8cfbfcca6d5_641b25b7073f7a2eac0242e8_Junior-field-e1541817239379.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/indiantrail',
      fkoSupported: false,
      dbatSupported: true,
      classesSupported: false,
      classesFreeTrialEnabled: false,
    },
    {
      siteId: '5730923',
      key: 'detroit',
      name: 'D-BAT Detroit',
      state: 'MI',
      country: 'US',
      phoneNumber: '+1 (248) 940-3228',
      region: DBAT_TOCA_REGION,
      timezone: 'America/New_York',
      utcOffset: EASTERN_UTC_OFFSET,
      dstOffset: EASTERN_DST_OFFSET,
      hubspotName: 'Detroit',
      coordinates: {
        lat: 42.46359058077519,
        lon: -83.56002931607206,
      },
      email: '',
      address: '51691 10 Mile Rd, South Lyon, MI 48178',
      logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/645d24166df7a8cfbfcca6d5_641b25b7073f7a2eac0242e8_Junior-field-e1541817239379.jpeg',
      centerUrl: 'https://www.tocafootball.com/centers/detroit',
      fkoSupported: false,
      dbatSupported: true,
    },
  ].sort((a, b) => a.name.localeCompare(b.name)),
];

export const tocaLocationsWithoutWorkingMboSiteId: TocaLocation[] = [
  {
    siteId: '111111', // not mbosite
    key: 'farmington',
    name: 'Farmington',
    state: 'MI',
    country: 'US',
    phoneNumber: '(248) 957-8391',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Farmington',
    coordinates: {
      lat: 42.45872851871848,
      lon: -83.3774365,
    },
    email: 'farmington@tocafootball.com',
    address: '22777 Farmington Rd., Farmington MI 48336',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/farmington',
    fkoSupported: false,
  },
  {
    siteId: '222222', // not mbosite
    key: 'eastlake',
    name: 'Eastlake',
    state: 'OH',
    country: 'US',
    phoneNumber: '+1 440 527 8445',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Eastlake',
    coordinates: {
      lat: 41.63750163944123,
      lon: -81.4409946158843,
    },
    email: '',
    address: '34650 Melinz Pkwy, Eastlake, OH 44095',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/eastlake',
    fkoSupported: false,
  },
  {
    siteId: '333333', // not mbosite
    key: 'toledo',
    name: 'Toledo',
    state: 'OH',
    country: 'US',
    phoneNumber: '+1 419 874 9800',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Toledo',
    coordinates: {
      lat: 41.549621337714385,
      lon: -83.58775628465702,
    },
    email: '',
    address: '10020 S. Compass Dr Rossford, OH 43460',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/toledo',
    fkoSupported: false,
  },
  {
    siteId: '444444', // not mbosite
    key: 'noviwest',
    name: 'Novi West',
    state: 'MI',
    country: 'US',
    phoneNumber: '+1 248 374 0500',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Novi West',
    coordinates: {
      lat: 42.48560053887462,
      lon: -83.5012366,
    },
    email: '',
    address: '46039 Grand River Avenue, Novi MI 48374',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/noviwest',
    fkoSupported: false,
  },
  {
    siteId: '555555', // not mbosite
    key: 'novieast',
    name: 'Novi East',
    state: 'MI',
    country: 'US',
    phoneNumber: '+1 248 374 0500',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Novi East',
    coordinates: {
      lat: 42.47928839475285,
      lon: -83.45525415667778,
    },
    email: '',
    address: '41550 Grand River Avenue, Novi, MI 48375',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/novieast',
    fkoSupported: false,
  },
  {
    siteId: '666666', // not mbosite
    key: 'westlake',
    name: 'Westlake',
    state: 'OH',
    country: 'US',
    phoneNumber: '+1 440 331 0100',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Westlake',
    coordinates: {
      lat: 41.47136214276885,
      lon: -81.8793844365517,
    },
    email: '',
    address: '23409 Detroit Road, Westlake, OH 44145',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/westlake',
    fkoSupported: false,
  },
  {
    siteId: '777777', // not mbosite
    key: 'rockyriver',
    name: 'Rocky River',
    state: 'OH',
    country: 'US',
    phoneNumber: '+1 440 331 0100',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Rocky River',
    coordinates: {
      lat: 41.46286346488459,
      lon: -81.85511366931405,
    },
    email: '',
    address: '21220 Center Ridge Road, Rocky River, OH 44116',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/rockyriver',
    fkoSupported: false,
  },
  {
    siteId: '888888', // not mbosite
    key: 'northfield-warrensvilleheights',
    name: 'Northfield-Warrensville Heights',
    state: 'OH',
    country: 'US',
    phoneNumber: '+1 216 464 7122',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Northfield-Warrensville Heights',
    coordinates: {
      lat: 41.438076837681585,
      lon: -81.52287572328513,
    },
    email: '',
    address: '4505 Northfield Road, Warrensville Heights, OH 44128',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/64b16b20c87286376b83c407_63e58a406a7222ed5a320dfd_hero-header-1%20(1).png',
    centerUrl: 'https://www.tocafootball.com/centers/northfieldwarrensvilleheights',
    fkoSupported: false,
  },
  {
    siteId: '999999', // not mbosite
    key: 'cincinnati',
    name: 'Cincinnati',
    state: 'OH',
    country: 'US',
    phoneNumber: '+1 513 630 1276',
    region: TOCA_REGION,
    timezone: 'America/New_York',
    utcOffset: EASTERN_UTC_OFFSET,
    dstOffset: EASTERN_DST_OFFSET,
    hubspotName: 'Cincinnati',
    coordinates: {
      lat: 39.200594032532535,
      lon: -84.23959122923993,
    },
    email: '',
    address: '5999 Meijer Drive, Milford, OH 45150',
    logo: 'https://assets-global.website-files.com/60c7be61132e3ad0b40a333d/6500a6cf870531f080608dd9_adult-league-banner%20(1).jpeg',
    centerUrl: 'https://www.tocafootball.com/centers/cincinnati',
    fkoSupported: false,
  },
  // end tocaLocationsWithoutWorkingMboSiteId - DO NOT APPEND LOCATIONS HERE UNLESS THEY ARE NOT MBO SITES
];

// TODO should we have a version of this filtering by "fkoSupported"?
export const tocaLocationsByCountryAndStateMap = tocaLocations.reduce((map, loc) => {
  map[loc.country] ||= {};
  map[loc.country][loc.state] ||= [];
  map[loc.country][loc.state].push(loc);
  return map;
}, {} as {[country: string]: {[stateAbbrev: string]: TocaLocation[]}});

export const fkoLocationsByCountryAndStateMap = tocaLocations.reduce((map, loc) => {
  if (loc.fkoSupported) {
    map[loc.country] ||= {};
    map[loc.country][loc.state] ||= [];
    map[loc.country][loc.state].push(loc);
  }
  return map;
}, {} as {[country: string]: {[stateAbbrev: string]: TocaLocation[]}});

export const dbatLocationsByCountryAndStateMap = tocaLocations.reduce((map, loc) => {
  if (loc.dbatSupported) {
    map[loc.country] ||= {};
    map[loc.country][loc.state] ||= [];
    map[loc.country][loc.state].push(loc);
  }
  return map;
}, {} as {[country: string]: {[stateAbbrev: string]: TocaLocation[]}});

export const assessmentLocationsByCountryAndStateMap = tocaLocations.reduce((map, loc) => {
  if (loc.playerAssessmentSupported) {
    map[loc.country] ||= {};
    map[loc.country][loc.state] ||= [];
    map[loc.country][loc.state].push(loc);
  }
  return map;
}, {} as {[country: string]: {[stateAbbrev: string]: TocaLocation[]}});

export const classesLocationsByCountryAndStateMap = tocaLocations.reduce((map, loc) => {
  if (loc.classesSupported) {
    map[loc.country] ||= {};
    map[loc.country][loc.state] ||= [];
    map[loc.country][loc.state].push(loc);
  }
  return map;
}, {} as {[country: string]: {[stateAbbrev: string]: TocaLocation[]}});

// TODO this whole pattern needs to be re-evaluated
const getLocationsByCountryAndStateList = ({
  sport = 'toca',
}: {
  sport: 'dbat' | 'toca' | 'playerAssessment' | 'classes';
}) => {
  const locationsByCountryAndStateList: Array<{
    code: string;
    states: Array<{state: string; locations: TocaLocation[]}>;
  }> = [];

  for (const [countryCode, statesMap] of Object.entries(
    sport === 'dbat'
      ? dbatLocationsByCountryAndStateMap
      : sport === 'playerAssessment'
      ? assessmentLocationsByCountryAndStateMap
      : sport === 'classes'
      ? classesLocationsByCountryAndStateMap
      : fkoLocationsByCountryAndStateMap
  )) {
    const countryObj: (typeof locationsByCountryAndStateList)[0] = {
      code: countryCode,
      states: [],
    };
    for (const [stateAbbrev, locations] of Object.entries(statesMap)) {
      const sortedLocations = sortObjectsByStringProperty([...locations], 'name');
      countryObj.states.push({state: stateAbbrev, locations: sortedLocations});
    }
    sortObjectsByStringProperty(countryObj.states, 'state');
    locationsByCountryAndStateList.push(countryObj);
  }

  return locationsByCountryAndStateList;
};

export const tocaLocationsByCountryAndStateList = getLocationsByCountryAndStateList({sport: 'toca'});
export const tocaUSStatesAndLocationsList = tocaLocationsByCountryAndStateList.find((c) => 'US' === c.code)?.states;
export const tocaCAStatesAndLocationsList = tocaLocationsByCountryAndStateList.find((c) => 'CA' === c.code)?.states;

export const dbatLocationsByCountryAndStateList = getLocationsByCountryAndStateList({sport: 'dbat'});
export const dbatUSStatesAndLocationsList = dbatLocationsByCountryAndStateList.find((c) => 'US' === c.code)?.states;
export const dbatCAStatesAndLocationsList = dbatLocationsByCountryAndStateList.find((c) => 'CA' === c.code)?.states;

export const assessmentLocationsByCountryAndStateList = getLocationsByCountryAndStateList({sport: 'playerAssessment'});
export const assessmentUSStatesAndLocationsList = assessmentLocationsByCountryAndStateList.find(
  (c) => 'US' === c.code
)?.states;
export const assessmentCAStatesAndLocationsList = assessmentLocationsByCountryAndStateList.find(
  (c) => 'CA' === c.code
)?.states;

export const classesLocationsByCountryAndStateList = getLocationsByCountryAndStateList({sport: 'classes'});
export const classesUSStatesAndLocationsList = classesLocationsByCountryAndStateList.find(
  (c) => 'US' === c.code
)?.states;
export const classesCAStatesAndLocationsList = classesLocationsByCountryAndStateList.find(
  (c) => 'CA' === c.code
)?.states;

export function getLocationBySiteId(siteId: string) {
  return tocaLocations.find((location) => location.siteId === siteId + '');
}

export function getLocationByKey(key: string) {
  return tocaLocations.find((location) => location.key === key);
}
