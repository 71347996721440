import {ReactComponent as EyeIcon} from '../../../assets/img/icons/eye.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/img/icons/delete.svg';
import {CheckboxAlt} from 'components/Checkbox/Checkbox';
import {Button} from 'components/Connects/Button';
import Spinner from 'components/Loader/Spinner';
import Dropdown, {DropdownOption} from 'components/Dropdown/Dropdown';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {Profile} from 'user/player-info.interface';
import {generateAgeGroupOptions} from '../utils';

const ageGroupOptions = generateAgeGroupOptions();
const sortByOptions = [
  {displayValue: 'Highest Accuracy', value: 'highest-accuracy'},
  {displayValue: 'Fastest SOP', value: 'fastest-sop'},
  {displayValue: 'Highest Accuracy + SOP', value: 'highest-accuracy-and-fastest-sop'},
];

interface AssessmentViewProps {
  assessmentName: string;
  players: Profile[];
  selectedPlayers: string[];
  ageGroup: DropdownOption | null;
  sortBy: DropdownOption | null;
  isLoading: boolean;
  showDeletePlayerModal: boolean;
  setSelectAllPlayers: () => void;
  togglePlayerSelection: (playerId: string) => void;
  onClickViewPlayerAssessmentData: (playerId: string) => void;
  onClickDeletePlayerHandler: (playerId: string) => void;
  onClickDeletePlayerConfirm: () => void;
  setShowDeletePlayerModal: (show: boolean) => void;
  setSortBy: (option: DropdownOption) => void;
  setAgeGroup: (option: DropdownOption) => void;
  onClickExportHandler: () => void;
}

const AssessmentView: React.FC<AssessmentViewProps> = ({
  assessmentName,
  players,
  selectedPlayers,
  ageGroup,
  sortBy,
  isLoading,
  showDeletePlayerModal,
  setSelectAllPlayers,
  togglePlayerSelection,
  onClickViewPlayerAssessmentData,
  onClickDeletePlayerHandler,
  onClickDeletePlayerConfirm,
  setShowDeletePlayerModal,
  setSortBy,
  setAgeGroup,
  onClickExportHandler,
}) => {
  if (isLoading) {
    return (
      <div className="w-full h-full flex flex-1 items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col h-full w-full justify-between">
      <ConfirmationModal
        isOpen={showDeletePlayerModal}
        message="Are you sure you want to delete player from assessment?"
        onConfirm={onClickDeletePlayerConfirm}
        onCancel={() => setShowDeletePlayerModal(false)}
      />
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col">
          <div className="flex items-center justify-between mt-5">
            <span className="text-base text-primary font-semibold">{assessmentName}</span>
            <div className="flex items-center">
              {players.length ? (
                <>
                  <CheckboxAlt
                    id="selectall"
                    checked={players.length === selectedPlayers.length}
                    onChange={setSelectAllPlayers}
                  />
                  <p className="ml-2 text-xs">Select All</p>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex mt-4 space-x-4">
            <Dropdown placeholder="Sort By" defaultOption={sortBy} options={sortByOptions} onSelect={setSortBy} />
            <Dropdown
              placeholder="Age Group"
              defaultOption={ageGroup}
              options={ageGroupOptions}
              onSelect={setAgeGroup}
            />
          </div>

          <div className="flex-1 bg-white rounded-md mt-5 shadow-lg">
            {players.length ? (
              players.map((player) => (
                <div key={player._id} className="flex items-center mt-5 pb-5 pl-6 border-b border-grey">
                  <div className="flex items-center justify-between">
                    <CheckboxAlt
                      id={player._id}
                      checked={selectedPlayers.includes(player._id)}
                      onChange={() => togglePlayerSelection(player._id)}
                    />
                    <div className="text-grey-xdark text-sm leading-lg ml-2">
                      {player.firstName} {player.lastName}
                    </div>
                  </div>
                  <div className="ml-auto flex items-center mr-6">
                    <EyeIcon
                      className="mr-4 cursor-pointer"
                      onClick={() => onClickViewPlayerAssessmentData(player._id)}
                    />
                    <DeleteIcon className="cursor-pointer" onClick={() => onClickDeletePlayerHandler(player._id)} />
                  </div>
                </div>
              ))
            ) : (
              <p className="mt-4 text-center text-sm">No players found</p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4 flex-none">
        <Button
          disabled={!selectedPlayers.length}
          type="submit"
          color="secondary"
          variant="cta"
          text="EXPORT TO EXCEL"
          onClick={onClickExportHandler}
        />
      </div>
    </div>
  );
};

export default AssessmentView;
